
.goods-img-content {
  padding: 30px 30px 0 30px;
  height: calc(100% - 30px);
  /deep/ .pw-table-header {
    background-color: #f3f4fa;
    color: #14141c;
    font-size: 14px;
  }
  .pw-store-banner {
    height: 80px;
    width: 100%;
    display: flex;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    .goods-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .goods-item {
        width: 80px;
        height: 80px;
        margin-right: 10px;
        cursor: pointer;
        border: 1px solid #d1d2db;
        &:hover {
          border: 1px solid #eee;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .pf-act-content {
    display: flex;
    justify-content: center;
    align-items: center;
    .pw-input {
      width: 100px;
      margin-right: 8px;
    }
    .pw-confirm-btn {
      background-color: #1222d8;
      border-color: #1222d8;
    }
  }
}
