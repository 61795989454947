
.store-banner-score {
    padding: 30px;
    height: calc(100% - 30px);
    padding: 30px 30px 0px 30px;
    /deep/ .pw-table-header {
        background-color: #F3F4FA;
        color: #14141C;
        font-size: 14px;
    }
    .pw-store-banner {
        height: 80px;
        width: 100%;
        display: flex;
        padding: 0 20px;
        justify-content: center;
        align-items: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .pf-act-content {
        display: flex;
        justify-content: center;
        align-items: center;
        .pw-input {
            width: 100px;
            margin-right: 8px;
        }
        .pw-confirm-btn {
            background-color: #1222D8;
            border-color: #1222D8;
        }
    }

}
