
.answer-score-container {
  padding: 30px 30px 0 30px;
  display: flex;
  overflow-x: auto;
  height: calc(100% - 30px);
  ::v-deep .pw-table-header {
    background-color: #f3f4fa;
    color: #14141c;
    font-size: 14px;
  }
  .score-container-main {
    ::v-deep .el-button--primary {
      background-color: rgba(17, 34, 216, 1);
      border-color: rgba(17, 34, 216, 1);
    }
    .pages-center {
      margin-top: 5px;
      text-align: center;
    }
    .operation-flex {
      display: flex;
    }
    .el-table {
      flex: 1;
    }
    .pw-page-pagination {
    }
  }
}
